import { Extensions } from '@k3imagine/imagine-extension-framework/dist';
import {
  BasketModalContent,
  Loader,
  Modal
} from '@k3imagine/self-serve-components';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { useFetch } from 'use-http';
import { useRequestContext } from '../../hooks';
import GlobalContext from '../../state/GlobalContext';
import { PaymentStatus, SaleResponse } from '../../types';
import { augmentUrlRoute } from '../../utils/urlUtils';
import * as S from './BasketModalWizard.styles';
import {
  BasketOverview,
  BasketValidation,
  Payment,
  PaymentStatus as PaymentStatusComponent,
  UserInfo
} from './index';
import { WizardSteps } from './WizardSteps';

type ModalWizardProps = {
  step: WizardSteps;
  showModal: boolean;
  handleShowModal: Function;
};

interface PaymentRedirectData {
  isRedirect: true;
  basketId: string | null;
  paymentStatus: PaymentStatus | null;
  source: string | null;
}

interface NotRedirectData {
  isRedirect: false;
}

interface BasketModalLocationState {
  redirectTo?: string;
  source?: string;
  paymentStatus?: PaymentStatus;
  basketId?: string;
}

const parseRedirectState = (
  state: BasketModalLocationState
): PaymentRedirectData | NotRedirectData => {
  if (!state || state.source !== 'adyen-redirect') return { isRedirect: false };

  return {
    isRedirect: true,
    source: state.source,
    basketId: state.basketId || null,
    paymentStatus: state.paymentStatus || null
  };
};

const BasketModalWizard = ({
  step,
  showModal,
  handleShowModal
}: ModalWizardProps) => {
  const requestContext = useRequestContext();
  const { state } = useLocation();

  const redirectInitialState = parseRedirectState(
    state as BasketModalLocationState
  );
  const [redirect, setRedirectState] = useState<
    PaymentRedirectData | NotRedirectData
  >(redirectInitialState);

  const [currentStep, setCurrentStep] = useState<WizardSteps>(step);
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus | null>(
    redirect.isRedirect ? redirect.paymentStatus : null
  );
  const [totalAmount, setTotalAmount] = useState<number>(); // this is the final amount that comes from external basket validation
  const [saleResponse, setSaleResponse] = useState<SaleResponse>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isRenderingExtension, setIsRenderingExtension] = useState<boolean>(
    false
  );
  const extensionIframeRef = React.useRef<HTMLIFrameElement>(null);

  const {
    clearBasket,
    customerInfo,
    updateCustomerInfo,
    rootComposerGroup,
    getComposerTiles,
    shopId,
    basket,
    paymentProvider
  } = useContext(GlobalContext);

  const basketId = redirect.isRedirect ? redirect.basketId : basket.externalUid;
  const [pendingBasketId, setPendingBasketId] = useState<string | null>(
    basketId
  );

  const {
    data: orderData,
    error: orderError,
    response: orderResponse,
    abort: orderAbort,
    get: getOrder
  } = useFetch<SaleResponse>({
    cache: 'no-cache',
    retryOn: [404],
    retryDelay: 1500,
    retries: 80 // 2 minutes (1.5s x 80 times)
  });

  const status =
    paymentProvider?.type === 'Adyen' && orderResponse.status === 404
      ? PaymentStatus.Pending
      : paymentStatus ?? PaymentStatus.Pending;

  // load order details to present in PaymentStatus component
  // NOTE: this is only used in payment result redirect
  useEffect(() => {
    if (
      paymentProvider?.type !== 'Adyen' ||
      currentStep !== WizardSteps.PaymentStatus
    )
      return;

    if (status === PaymentStatus.Failure) return;

    getOrder(`v1.0/sales/${pendingBasketId || basketId}`);

    return () => {
      orderAbort();
    };
  }, [currentStep, pendingBasketId || basketId, paymentProvider?.type, status]);

  const imagineExtensionFramework = useMemo(
    () =>
      new Extensions(
        Number(shopId),
        basketId ?? '',
        `${process.env.API_URL}/api/${process.env.API_VERSION}`,
        undefined,
        undefined,
        {
          TenantId: requestContext.tenantId,
          ShopId: requestContext.shopId,
          TableId: requestContext.tableId
        }
      ),
    [shopId, basketId]
  );

  const triggerExtensionEvent = async (event: string) => {
    setIsLoading(true);
    const extensionSubscriptions = await imagineExtensionFramework.triggerEvent(
      event
    );
    setIsLoading(false);
    if (extensionSubscriptions && extensionSubscriptions.length > 0) {
      setIsRenderingExtension(true);
      if (extensionIframeRef.current) {
        const extensionIframe: HTMLIFrameElement = extensionIframeRef.current;
        try {
          await imagineExtensionFramework.invokeExtensions(
            extensionSubscriptions,
            extensionIframe
          );
        } catch (e) {
          // eslint-disable-next-line
          console.error(e);
        }

        ReactDOM.unmountComponentAtNode(extensionIframe);
      }

      setIsRenderingExtension(false);
    }
  };

  const goToNextStep = () => {
    setCurrentStep(value => value + 1);
  };

  const goToPrevStep = () => {
    if (currentStep === WizardSteps.Payment) {
      // skip Basket Validation step if going back from Payment step
      setCurrentStep(value => value - 2);
    } else if (currentStep >= 1) {
      setCurrentStep(value => value - 1);
    }
  };

  const closeModal = () => {
    handleShowModal(false);
    setCurrentStep(WizardSteps.BasketOverview);
  };

  const goToStart = async (hide = true) => {
    if (hide) closeModal();

    if (
      redirect.isRedirect &&
      redirect.paymentStatus === PaymentStatus.Success
    ) {
      await clearBasket(true);
      setPendingBasketId(null);
      setRedirectState({ isRedirect: false });
    }

    await navigate(
      augmentUrlRoute(`/groups/${rootComposerGroup?.groupUrlName}`)
    );
  };

  const finishSale = async (response: {
    paymentStatus: PaymentStatus;
    salesResponse?: SaleResponse;
  }) => {
    if (response.paymentStatus === PaymentStatus.Success) {
      await triggerExtensionEvent('OnPaymentSuccessful');
      await clearBasket(true);
    }

    getComposerTiles();
    setPaymentStatus(response.paymentStatus);
    if (response.salesResponse) setSaleResponse(response.salesResponse);
    setCurrentStep(WizardSteps.PaymentStatus);
  };

  useEffect(() => {
    if (paymentStatus !== PaymentStatus.Success || !orderData || orderError)
      return;

    (async () => {
      await triggerExtensionEvent('OnPaymentSuccessful');
      await goToStart(false);
    })();
  }, [paymentStatus, orderData]);

  const renderContent = () => {
    if (isLoading) {
      return (
        <S.LoaderWrapper>
          <Loader width={200} color="black" />
        </S.LoaderWrapper>
      );
    }

    if (isRenderingExtension) {
      return (
        <BasketModalContent>
          <S.ExtensionIframe ref={extensionIframeRef} />
        </BasketModalContent>
      );
    }

    const order = saleResponse || orderData;

    switch (currentStep) {
      case WizardSteps.BasketOverview: {
        return (
          <BasketOverview
            goNext={async () => {
              await triggerExtensionEvent('OnBeginCheckout');
              goToNextStep();
            }}
            closeModal={closeModal}
          />
        );
      }
      case WizardSteps.UserInfo: {
        return (
          <UserInfo
            customerInfo={customerInfo}
            saveCustomerInfo={updateCustomerInfo}
            goBack={goToPrevStep}
            goNext={goToNextStep}
          />
        );
      }
      case WizardSteps.BasketValidation: {
        return (
          <BasketValidation
            goNext={async (totalAmountData: number) => {
              setTotalAmount(totalAmountData);
              goToNextStep();
            }}
            goBack={goToPrevStep}
            goToStart={goToStart}
            goToPaymentStatus={(paymentStatusResponse: PaymentStatus) => {
              setPaymentStatus(paymentStatusResponse);
              setCurrentStep(WizardSteps.PaymentStatus);
            }}
          />
        );
      }
      case WizardSteps.Payment: {
        return (
          <Payment
            totalAmount={totalAmount || 0}
            goBack={goToPrevStep}
            goNext={finishSale}
          />
        );
      }
      case WizardSteps.PaymentStatus: {
        return (
          <PaymentStatusComponent
            orderId={order?.orderId}
            averageWaitingTimeInSeconds={order?.averageWaitTimeSeconds}
            status={status}
            onGoToBasketOverview={() =>
              setCurrentStep(WizardSteps.BasketOverview)
            }
            onGoToStart={goToStart}
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <Modal isHidden={!showModal} onClose={closeModal}>
      <S.ContentWrapper>{renderContent()}</S.ContentWrapper>
    </Modal>
  );
};

export default BasketModalWizard;
